import Vue from 'vue'

// axios
import axios from 'axios'


const axiosInternal = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${globalThis.env["PROTOCOL"]}://${globalThis.env["API_URL"]}/`,
  headers: { 'app_name': 'voyce' }
})
const axiosWHeader = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${globalThis.env["PROTOCOL"]}://${globalThis.env["API_URL"]}/`,
  headers: { 'app_name': 'voyce' }
})
const axiosExternal = axios.create({
  // You can add your headers here
  // ================================
  baseURL: ``,
})

Vue.prototype.$http_in = axiosInternal
Vue.prototype.$http_ex = axiosExternal
Vue.prototype.$http_wh = axiosExternal

export default axiosInternal
