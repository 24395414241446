<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-row>
      <b-col sm="12" md="4" lg="4">
        <b-card no-body class="mb-50">
          <b-card-body class="pb-0" style="max-height: 28rem; overflow-y: auto">
            <b-card-text class="mb-1">
              <div class="d-flex align-items-center">
                <i class="bi bi-person font-medium-3"></i>
                <span
                  class="font-medium-3 font-weight-bold ml-50 text-truncate"
                  style="max-width: 15rem"
                >
                  {{ selected_customer["Adı Soyadı"] }}
                </span>
                <b-button
                  v-b-tooltip.hover.top="lang('t_edit')"
                  v-b-tooltip.hover.v-secondary
                  v-if="crm_permissions.includes('edit_customer')"
                  @click="openUpdateCustomerModal"
                  class="ml-auto px-50 py-50"
                  size="sm"
                  variant="flat-secondary"
                >
                  <i class="bi bi-pencil"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="lang('t_add')"
                  v-b-tooltip.hover.v-secondary
                  v-if="crm_permissions.includes('extra_data')"
                  @click="modal_extra_data = true"
                  class="ml-50 px-50 py-50"
                  size="sm"
                  variant="flat-secondary"
                >
                  <i class="bi bi-plus font-medium-1"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover.top="lang('t_addFile')"
                  v-b-tooltip.hover.v-secondary
                  v-if="crm_permissions.includes('extra_data')"
                  @click="modal_add_file = true"
                  class="ml-50 px-50 py-50"
                  size="sm"
                  variant="flat-secondary"
                >
                  <i class="bi bi-paperclip font-medium-1"></i>
                </b-button>
                <!-- v-if="script.length > 0 && crm_permissions.includes('script')" -->
                <!-- 
                <b-button
                  v-b-tooltip.hover.top="lang('t_script')"
                  v-b-tooltip.hover.v-secondary
                  @click="modal_script = true"
                  class="ml-50 px-50 py-50"
                  size="sm"
                  variant="flat-secondary"
                >
                  <i class="bi bi-card-text font-medium-1"></i>
                </b-button> -->
              </div>
              <div class="text-truncate d-flex align-items-center">
                <span class="text-muted">
                  {{ selected_customer["Müşteri No"] }}
                </span>
                <!-- <b-badge
                  v-if="selected_customer['assigned_agent']"
                  class="ml-auto"
                  variant="light-success"
                  >{{ selected_customer["assigned_agent"].split("@")[0] }}
                </b-badge> -->
              </div>
            </b-card-text>
            <b-card-text>
              <div class="d-flex align-items-start">
                <b-button
                  @click="modal_old_notes = true"
                  v-if="old_notes.length > 0"
                  size="sm"
                  variant="gradient-primary"
                  class="mr-25"
                >
                  {{ lang("t_notes") }}</b-button
                >
                <b-button
                  ref="vehicleButton"
                  v-if="selected_customer['Araç'] == 'Var'"
                  @click="openModalSpecialData('Araç', 'vehicleButton')"
                  size="sm"
                  variant="gradient-primary"
                  class="mr-25"
                >
                  {{ lang("t_vehicle") }}</b-button
                >
                <b-button
                  ref="bankButton"
                  v-if="selected_customer['Banka'] == 'Var'"
                  @click="openModalSpecialData('Banka', 'bankButton')"
                  size="sm"
                  variant="gradient-primary"
                  class="mr-25"
                >
                  {{ lang("t_bank") }}</b-button
                >
                <b-button
                  ref="landRegistryButton"
                  v-if="selected_customer['Tapu'] == 'Var'"
                  @click="openModalSpecialData('Tapu', 'landRegistryButton')"
                  size="sm"
                  variant="gradient-primary"
                  class="mr-25"
                >
                  {{ lang("t_landRegistry") }}</b-button
                >
                <b-button
                  ref="sskButton"
                  v-if="selected_customer['SSK'] == 'Var'"
                  @click="openModalSpecialData('SSK', 'sskButton')"
                  size="sm"
                  variant="gradient-primary"
                  class="mr-25"
                  >{{ lang("t_SSK") }}</b-button
                >
                <b-button
                  ref="mernisButton"
                  v-if="selected_customer['Mernis'] == 'Var'"
                  @click="openModalSpecialData('Mernis', 'mernisButton')"
                  size="sm"
                  variant="gradient-primary"
                  class="mr-25"
                >
                  {{ lang("t_mernis") }}</b-button
                >
                <b-button
                  ref="gsmSorguButton"
                  v-if="selected_customer['Gsm Sorgu'] == 'Var'"
                  @click="openModalSpecialData('Gsm Sorgu', 'gsmSorguButton')"
                  size="sm"
                  variant="gradient-primary"
                  class="mr-25"
                  >{{ lang("t_gsmQuery") }}</b-button
                >
                <b-button
                  ref="muzekkereButton"
                  v-if="selected_customer['Muzekkere'] == 'Var'"
                  @click="openModalSpecialData('Muzekkere', 'muzekkereButton')"
                  size="sm"
                  variant="gradient-primary"
                >
                  {{ lang("t_negotiation") }}</b-button
                >
              </div>
            </b-card-text>
            <b-card-text class="" style="display: grid">
              <div class="mb-50 text-truncate">
                <span class="font-weight-bold">
                  {{ getLabelName("active") + ":" }}
                </span>
                <b-badge
                  class="ml-50"
                  :variant="
                    selected_customer['active'] == false
                      ? 'light-danger'
                      : 'light-success'
                  "
                >
                  {{
                    selected_customer["active"] == false
                      ? lang("t_passive")
                      : lang("t_active")
                  }}
                </b-badge>

                <span class="ml-50"> {{ selected_customer[key] }} </span>
              </div>
              <div
                v-for="(key, i) in Object.keys(selected_customer).filter(
                  (key) =>
                    ![
                      'TCKN_masked',
                      'insert_date',
                      'action_date',
                      'Müşteri No',
                      'Adı Soyadı',
                      'status',
                      'attempts',
                      'total_attempts',
                      '_id',
                      'view_moment',
                      'extra_data',
                      'phones',
                      'active',
                      'sms',
                      'products',
                      'notes',
                      'SSK',
                      'Araç',
                      'Banka',
                      'Tapu',
                      'Mernis',
                      'Muzekkere',
                      'Gsm Sorgu',
                      'files',
                    ].includes(key) &&
                    ![undefined, null, '', ' ', ' '].includes(
                      selected_customer[key]
                    )
                )"
                class="mb-50 text-truncate"
                :key="i"
              >
                <span class="font-weight-bold">
                  {{ getLabelName(key) + ":" }}
                </span>
                <b-badge
                  v-if="key == 'Kuyruk'"
                  class="ml-50"
                  variant="light-primary"
                >
                  {{
                    queues.find(
                      (e) => e.internal_name == selected_customer[key]
                    )
                      ? queues.find(
                          (e) => e.internal_name == selected_customer[key]
                        ).display_name
                      : selected_customer[key]
                  }}
                </b-badge>
                <b-badge
                  v-else-if="key == 'assigned_agent'"
                  class="ml-50"
                  variant="light-secondary"
                >
                  {{ selected_customer[key].split("@")[0] }}
                </b-badge>
                <span
                  class="ml-50"
                  v-else-if="
                    key == 'TCKN' && !crm_permissions.includes('show_tckn')
                  "
                >
                  {{ selected_customer["TCKN_masked"] }}
                </span>

                <span
                  v-b-tooltip.hover.top="selected_customer[key]"
                  v-b-tooltip.hover.v-secondary
                  v-else
                  class="ml-50"
                >
                  {{ selected_customer[key] }}
                </span>
              </div>
            </b-card-text>
            <b-card-text
              v-if="customer_extra_info.length > 0"
              style="display: grid"
            >
              <span class="mb-1 text-uppercase text-muted">
                Ekstra Bilgiler
              </span>
              <div
                v-for="(item, i) in customer_extra_info"
                class="mb-50 text-truncate d-flex align-items-center"
                :key="i"
              >
                <span class="font-weight-bold">
                  {{ getLabelName(item.column) + ":" }}
                </span>
                <span
                  v-b-tooltip.hover.top="item.value"
                  v-b-tooltip.hover.v-secondary
                  class="ml-50"
                >
                  {{ item.value }}
                </span>
                <b-button
                  @click="deleteExtraData(item)"
                  class="ml-auto px-50 py-50"
                  size="sm"
                  variant="flat-secondary"
                >
                  <i class="bi bi-trash font-medium-1"></i>
                </b-button>
              </div>
            </b-card-text>
          </b-card-body>

          <b-card-body class="pt-1 pb-50">
            <div class="mb-50">
              <small v-if="selected_customer['insert_date']" class="text-muted">
                {{
                  getLabelName("insert_date") +
                  ": " +
                  selected_customer["insert_date"]
                }}
              </small>
              <small
                v-if="
                  selected_customer['insert_date'] &&
                  selected_customer['action_date']
                "
                class="mx-50 text-muted"
                >|</small
              >
              <small v-if="selected_customer['action_date']" class="text-muted">
                {{
                  getLabelName("action_date") +
                  ": " +
                  selected_customer["action_date"]
                }}
              </small>
            </div>
            <div v-if="selected_customer['files']" class="mb-50">
              <b-badge
                target="_blank"
                :href="`${PROTOCOL}://${API_URL}/crm/v1/CustomerFile/${PROJECT}/${encodeURI(
                  encodeURI(file)
                )}`"
                class="mr-25 cursor-pointer"
                v-for="(file, i) in selected_customer['files']"
                :key="i"
                size="sm"
                variant="light-secondary"
                >{{ file }}</b-badge
              >
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body class="mb-0">
          <b-card-body class="pb-0">
            <div class="d-flex align-items-center">
              <span class="font-medium-3 font-weight-bold">Hareketler</span>
              <b-button
                v-b-tooltip.hover.top="lang('t_add')"
                v-b-tooltip.hover.v-secondary
                @click="modal_add_note = true"
                class="ml-auto px-50 py-50"
                size="sm"
                variant="flat-secondary"
              >
                <i class="bi bi-plus font-medium-1"></i>
              </b-button>
              <b-dropdown
                v-b-tooltip.hover.top="lang('t_filters')"
                v-b-tooltip.hover.v-secondary
                boundary="viewport"
                variant="flat-secondary"
                no-caret
                :right="$store.state.appConfig.isRTL"
                class="ml-50"
                toggle-class="px-50 py-50"
              >
                <template #button-content>
                  <i class="bi bi-funnel font-medium-1"></i>
                </template>

                <b-dropdown-form>
                  <div>
                    <b-form-checkbox v-model="activityLogFilters.notes">
                      <span class="align-middle ml-1">{{
                        lang("t_notes")
                      }}</span>
                    </b-form-checkbox>
                    <b-form-checkbox v-model="activityLogFilters.logs">
                      <span class="align-middle ml-1">{{
                        lang("t_logs")
                      }}</span>
                    </b-form-checkbox>
                    <b-form-checkbox v-model="activityLogFilters.sms">
                      <span class="align-middle ml-1">{{ lang("t_sms") }}</span>
                    </b-form-checkbox>
                  </div>
                </b-dropdown-form>
              </b-dropdown>
            </div>
            <b-input-group class="input-group-merge my-50">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="activitySearchQuery"
                :placeholder="lang('t_search')"
              ></b-form-input>
            </b-input-group>
          </b-card-body>
          <b-card-body style="max-height: 20rem; overflow-y: auto">
            <app-timeline>
              <app-timeline-item
                v-for="(item, i) in FilteredActivityList"
                :key="i"
                :title="item.agent.split('@')[0]"
                :subtitle="item.note"
                :icon="item.icon"
                :time="item.insert_date"
                :variant="item.color"
              />
            </app-timeline>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col sm="12" md="8" lg="8">
        <!-- <b-row>
          <b-col>
      
          </b-col>
        </b-row> -->
        <!-- PRODUCTS -->
        <b-row
          v-if="product_list.length > 0 && crm_permissions.includes('product')"
        >
          <b-col>
            <b-card no-body>
              <b-card-header class="d-block">
                <div class="d-flex align-items-center">
                  <span class="font-weight-bold">{{ lang("t_products") }}</span>
                  <b-button
                    @click="
                      modal_product_action = true;
                      selected_product_action = {};
                    "
                    :disabled="selected_item.length == 0"
                    v-b-tooltip.hover.bottom="lang('t_addAction')"
                    v-b-tooltip.hover.v-secondary
                    size="sm"
                    class="px-50 py-50 ml-auto"
                    variant="flat-secondary"
                  >
                    <i class="bi bi-check-circle font-medium-1"></i>
                  </b-button>
                </div>
              </b-card-header>

              <b-card-body class="px-0 pb-0" style="display: grid">
                <b-table
                  small
                  hover
                  :fields="product_list_header"
                  :items="product_list"
                  responsive
                  show-empty
                  :empty-text="lang('t_noRecord')"
                  :busy="product_list.length == 0"
                  class="mb-0"
                >
                  <template #empty="scope">
                    <b-container class="mt-5">
                      <b-row>
                        <b-col class="text-center text-muted mt-50">
                          <span>{{ scope.emptyText }}</span>
                        </b-col>
                      </b-row>
                    </b-container>
                  </template>
                  <template #row-details="row">
                    <!-- {{ row.item.product_logs }} -->
                    <b-card>
                      <b-table
                        v-if="row.item.product_logs"
                        style="max-width: 100vh; overflow-x: auto"
                        small
                        bordered
                        :items="
                          row.item.product_logs ? row.item.product_logs : []
                        "
                        :fields="product_log_header"
                        striped
                        responsive=""
                      >
                      </b-table>

                      <!-- <b-button
                        size="sm"
                        variant="outline-secondary"
                        @click="row.toggleDetails"
                      >
                        {{ lang("t_hide") }}
                      </b-button> -->
                    </b-card>
                  </template>

                  <template #cell(show_details)="row">
                    <b-button
                      @click="row.toggleDetails"
                      size="sm"
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon
                        :icon="
                          !row.detailsShowing ? 'ArrowDownIcon' : 'ArrowUpIcon'
                        "
                      ></feather-icon>
                    </b-button>
                  </template>

                  <template #head(select)>
                    <!-- <b-form-checkbox
                      @change="selectAll"
                      class="custom-control-primary"
                    >
                      <span class="vs-checkbox">
                        <span class="vs-checkbox--check">
                          <i class="vs-icon feather icon-check" />
                        </span>
                      </span>
                    </b-form-checkbox> -->
                  </template>
                  <template #cell(select)="data">
                    <b-form-checkbox
                      @change="selection(data.item)"
                      :checked="selected_item.filter((e) => e == data.item) > 0"
                      v-model="data.item.is_selected"
                      class="custom-control-primary"
                    >
                      <span class="vs-checkbox">
                        <span class="vs-checkbox--check">
                          <!-- <i class="vs-icon feather icon-check" /> -->
                        </span>
                      </span>
                    </b-form-checkbox>
                  </template>

                  <template #cell(BAKIYE_TOPLAMI)="data">
                    <div class="d-flex align-items-center">
                      <a
                        @click="openProductDetails(data.item)"
                        style="width: 7rem"
                        class="my-auto"
                        href="#"
                      >
                        {{ data.value }}</a
                      >
                    </div>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <b-dropdown
                      boundary="viewport"
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item @click="confirmText(data.item)">
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">{{
                          lang("t_delete")
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>

        <!-- PHONES -->
        <b-row>
          <b-col>
            <b-card no-body>
              <b-card-header class="d-block">
                <div class="d-flex align-items-center">
                  <span class="font-weight-bold">{{ lang("t_phones") }}</span>

                  <b-button
                    v-if="crm_permissions.includes('edit_customer')"
                    @click="openUpdateCustomerPhonesModal"
                    v-b-tooltip.hover.bottom="lang('t_phonesUpdate')"
                    v-b-tooltip.hover.v-secondary
                    size="sm"
                    class="px-50 py-50 ml-auto mr-50"
                    variant="flat-secondary"
                  >
                    <i class="bi bi-pencil"></i>
                  </b-button>
                  <b-button
                    @click="modal_add_phone = true"
                    v-b-tooltip.hover.bottom="lang('t_addPhone')"
                    v-b-tooltip.hover.v-secondary
                    size="sm"
                    class="px-50 py-50"
                    :class="
                      crm_permissions.includes('edit_customer') ? '' : 'ml-auto'
                    "
                    variant="flat-secondary"
                  >
                    <i class="bi bi-plus"></i>
                  </b-button>
                </div>
              </b-card-header>

              <b-card-body class="px-0 pb-0" style="display: grid">
                <b-table
                  small
                  hover
                  responsive
                  show-empty
                  :empty-text="lang('t_noRecord')"
                  :busy="phone_list.length == 0"
                  class="mb-0"
                  :items="phone_list"
                  :fields="phone_list_header"
                  striped
                  ref="tablePhone"
                  :tbody-tr-class="rowClassPhone"
                >
                  <template #cell(reached)="data">
                    <feather-icon
                      :icon="data.value ? 'CheckIcon' : 'XIcon'"
                      size="12"
                      class="align-middle text-body"
                    />
                  </template>
                  <template #cell(reached_date)="data">
                    {{
                      new Date(data.value ? data.value : null)
                        .toISOString()
                        .split("T")[0]
                    }}
                  </template>
                  <template #cell(show_details)="row">
                    <b-button
                      @click="row.toggleDetails"
                      size="sm"
                      variant="flat-secondary"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon
                        :icon="
                          !row.detailsShowing ? 'ArrowDownIcon' : 'ArrowUpIcon'
                        "
                      ></feather-icon>
                    </b-button>
                  </template>

                  <template #cell(Telefon)="data">
                    <div class="d-flex align-items-center">
                      <b-button
                        class="mr-50 px-50 py-50"
                        @click="
                          () => {
                            data.item.Onaylı = !data.item.Onaylı;
                            setApproved(data.item);
                          }
                        "
                        size="sm"
                        variant="flat-secondary"
                      >
                        <i
                          :class="[
                            data.item.Onaylı
                              ? 'text-warning bi bi-star-fill'
                              : 'text-warning bi bi-star',
                          ]"
                        ></i>
                      </b-button>
                      <b-button
                        v-if="
                          other_permissions &&
                          !other_permissions.includes('mask_remote_number')
                        "
                        class="mr-50 px-50 py-50"
                        @click="doCopy(data.value)"
                        size="sm"
                        variant="flat-secondary"
                      >
                        <i :class="['text-secondary bi bi-clipboard']"></i>
                      </b-button>
                      <a
                        style="width: 7rem"
                        class="my-auto"
                        href="#"
                        @click="start_preview_call(data.item.Telefon)"
                      >
                        {{
                          other_permissions.includes(
            "mask_remote_number"
          )
            ? maskPhoneNumber(data.item.Telefon)
            : data.item.Telefon,
                        }}</a
                      >

                      <b-badge
                        v-b-tooltip.hover.bottom="lang('t_attempts')"
                        v-b-tooltip.hover.v-secondary
                        class="ml-50"
                        variant="light-secondary"
                        pill
                        >{{ data.item.attempts }}
                      </b-badge>
                      <b-badge
                        v-b-tooltip.hover.bottom="lang('t_totalAttempts')"
                        v-b-tooltip.hover.v-secondary
                        class="ml-50"
                        variant="light-secondary"
                        pill
                        >{{ data.item.total_attempts }}
                      </b-badge>
                    </div>

                    <!-- <b-button
                      v-if="crm_permissions.includes('sms_send')"
                      @click="
                        modal_send_sms = true;
                        sms_phone = data.item.Telefon;
                      "
                      variant="outline-primary"
                      class="ml-25 px-50 py-25 float-right"
                      size="sm"
                    >
                      <feather-icon icon="SendIcon"></feather-icon>
                    </b-button>
                    <b-button
                      @click="
                        () => {
                          f_action.contact_number = data.item.Telefon;
                          modal_finish_code = true;
                        }
                      "
                      class="ml-25 px-50 py-25 float-right"
                      size="sm"
                      variant="outline-primary"
                    >
                      <feather-icon icon="TagIcon"></feather-icon>
                    </b-button>
                    <b-button
                      @click="openDeleteModal(data.item)"
                      v-if="data.item.is_research == true"
                      variant="outline-danger"
                      class="ml-25 px-50 py-25 float-right"
                      size="sm"
                    >
                      <feather-icon icon="Trash2Icon" class="text-danger" />
                    </b-button> -->
                  </template>
                  <!-- <template #cell(Onaylı)="data">
                    <b-form-checkbox
                      size="sm"
                      @change="setApproved(data.item)"
                      v-model="data.item.Onaylı"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </template> -->
                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                      boundary="viewport"
                      dropleft
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <!-- <b-dropdown-item
                        v-if="crm_permissions.includes('sms_send')"
                        @click="
                          modal_send_sms = true;
                          sms_phone = data.item.Telefon;
                        "
                      >
                        <i class="bi bi-send"></i>
                        <span class="align-middle ml-50">{{
                          lang("t_sendSMS")
                        }}</span>
                      </b-dropdown-item> -->
                      <b-dropdown-item
                        @click="
                          () => {
                            f_action.contact_number = data.item.Telefon;
                            modal_finish_code = true;
                          }
                        "
                      >
                        <i class="bi bi-check-circle"></i>
                        <span class="align-middle ml-50">{{
                          lang("t_addFinishCode")
                        }}</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="data.item.is_research == true"
                        @click="openDeleteModal(data.item)"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">{{
                          lang("t_delete")
                        }}</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>

                  <template #row-details="row">
                    <b-card
                      class="px-0 mx-0"
                      style="max-width: 100%; overflow-x: auto"
                    >
                      <b-table
                        small
                        hover
                        :items="Object.values(row.item.phone_log)"
                        :fields="phone_log_header"
                        striped
                        @row-dblclicked="loadSound"
                      >
                        <template #cell(Direction)="data">
                          <b-icon
                            icon="telephone-inbound"
                            v-if="data.value == 'inbound'"
                            v-b-tooltip.hover.v-secondary
                            v-b-tooltip.hover.bottom="'inbound'"
                          ></b-icon>
                          <b-icon
                            icon="telephone-outbound"
                            v-else-if="data.value == 'outbound'"
                            v-b-tooltip.hover.v-secondary
                            v-b-tooltip.hover.bottom="'outbound'"
                          ></b-icon>
                          <b-icon
                            icon="telephone-outbound"
                            v-else-if="data.value == 'dialer'"
                            v-b-tooltip.hover.v-secondary
                            v-b-tooltip.hover.bottom="'dialer'"
                          ></b-icon>
                          <b-icon
                            icon="chat-dots"
                            v-else-if="data.value == 'text'"
                            v-b-tooltip.hover.v-secondary
                            v-b-tooltip.hover.bottom="'text'"
                          ></b-icon>
                          <b-icon
                            icon="card-checklist"
                            v-else-if="data.value == 'manual'"
                            v-b-tooltip.hover.v-secondary
                            v-b-tooltip.hover.bottom="'manual'"
                          ></b-icon>
                          <span v-else> {{ data.value }}</span>
                        </template>
                        <template #cell(Verdict)="data">
                          <div class="d-flex align-items-center">
                            <span>{{ data.value }}</span>
                            <b-icon
                              v-if="data.item.TransferEvent"
                              class="ml-50 text-success"
                              icon="telephone-forward"
                            ></b-icon>
                          </div>
                        </template>

                        <template #cell(Queue)="data">
                          <b-badge
                            class="ml-50"
                            variant="primary"
                            style="opacity: 0.7"
                          >
                            {{
                              queues.find((e) => e.internal_name == data.value)
                                ? queues.find(
                                    (e) => e.internal_name == data.value
                                  ).display_name
                                : data.value
                            }}
                          </b-badge>
                        </template>
                        <template #cell(Note)="data">
                          <b-button
                            v-if="
                              data.value.length > 0 &&
                              !crm_permissions.includes('show_note_details')
                            "
                            variant="light-secondary"
                            @click="openNote(data.value)"
                            class="py-50 px-50"
                          >
                            <i class="bi bi-sticky font-medium-1"></i>
                          </b-button>
                          <span
                            v-if="crm_permissions.includes('show_note_details')"
                            >{{ data.value }}</span
                          >
                        </template>
                        <template #cell(TransferEvent)="data">
                          <center>
                            <feather-icon
                              class="text-danger"
                              v-if="!data.value"
                              icon="XOctagonIcon"
                            >
                            </feather-icon>
                            <feather-icon
                              class="text-success"
                              v-else
                              icon="CheckCircleIcon"
                            >
                            </feather-icon>
                          </center>
                        </template>
                      </b-table>
                      <!-- <b-button
                        @click="
                          () => {
                            f_action.contact_number = row.item.Telefon;
                            modal_finish_code = true;
                          }
                        "
                        class="ml-1"
                        size="sm"
                        variant="outline-primary"
                      >
                        {{ lang("t_addFinishCode") }}
                      </b-button> -->
                    </b-card>
                  </template>
                </b-table>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row
      :class="displaySound ? 'd-block' : 'd-none'"
      class="py-0 px-0 mx-0 my-0"
      style="
        position: fixed;
        bottom: 0;
        z-index: 9999;
        left: 0;
        width: 100vw;
        margin-left: 0;
        margin-right: 0;
      "
    >
      <b-col class="py-0 px-0 mx-0 my-0">
        <b-card class="mb-0">
          <b-card-text>
            <b-row>
              <b-col class="d-flex align-items-center justify-content-center">
                <b-dropdown
                  boundary="viewport"
                  v-if="wavesurfer"
                  class="mr-50"
                  :text="wavesurfer.getPlaybackRate() + 'x'"
                  variant="flat-secondary"
                >
                  <b-dropdown-item @click="setSoundSpeed(0.25)">
                    0.25x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(0.5)">
                    0.5x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(0.75)">
                    0.75x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1)">
                    1x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.25)">
                    1.25x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.5)">
                    1.50x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(1.75)">
                    1.75x
                  </b-dropdown-item>
                  <b-dropdown-item @click="setSoundSpeed(2)">
                    2x
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="20" icon="SkipBackIcon" />
                </b-button>
                <b-button
                  variant="outline-secondary"
                  class="btn-icon rounded-circle mx-50"
                  @click="
                    () => {
                      wavesurfer.playPause();
                    }
                  "
                >
                  <feather-icon
                    v-if="wavesurfer"
                    size="20"
                    :icon="wavesurfer.isPlaying() ? 'PauseIcon' : 'PlayIcon'"
                  />
                </b-button>
                <b-button
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon size="20" icon="SkipForwardIcon" />
                </b-button>
                <span style="width: 3rem" class="ml-50">
                  {{ `${formatTime(parseInt(currentTime))}` }}
                </span>
                <b-col
                  class="cursor-pointer"
                  :id="`waveform_${selected_customer._id}`"
                ></b-col>
                <span
                  style="width: 3rem"
                  v-if="wavesurfer && wavesurfer.decodedData"
                >
                  {{
                    `${formatTime(parseInt(wavesurfer.decodedData.duration))}`
                  }}
                </span>
                <b-button
                  @click="closeSound"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle ml-50"
                >
                  <feather-icon size="20" icon="XIcon" />
                </b-button>
              </b-col>
            </b-row>

            <!-- <div id="hover"></div> -->
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <!-- ADD FINISH CODE -->
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      static
      centered
      @ok="saveFinishCode"
      :title="lang('t_addFinishCode')"
      v-model="modal_finish_code"
      max-width="540"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
    >
      <b-form-group :label="lang('t_addFinishCode')" label-for="finish_code">
        <div class="d-flex align-items-center">
          <v-select
            class="w-100"
            v-model="f_action.finish_code"
            :options="finish_codes.filter((e) => !e.is_product_finish_code)"
            label="finish_code"
            :placeholder="lang('t_addFinishCode')"
            :reduce="(val) => val"
          ></v-select>
          <b-form-checkbox
            v-b-tooltip.hover.v-primary
            v-b-tooltip.hover.bottom="lang('t_lastFinishCode')"
            v-if="f_action.finish_code"
            class="ml-1"
            v-model="f_action.finish_code.is_last_finish_code"
          >
          </b-form-checkbox>
        </div>
      </b-form-group>
      <b-form-group
        v-if="f_action.finish_code && f_action.finish_code.require_date"
        :label="lang('t_actionDate')"
        label-for="action_date"
      >
        <!-- <b-form-datepicker id="action_date" v-model="f_action.action_date" /> -->
        <flat-pickr
          v-model="f_action.action_date"
          class="form-control"
          :title="lang('t_actionDate')"
          :placeholder="lang('t_actionDate')"
          :config="{
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            allowInput: true,
            time_24hr: true,
          }"
        />
      </b-form-group>
      <b-form-group :label="lang('t_note')" label-for="note">
        <b-form-textarea
          v-model="f_action.note"
          id="textarea-default"
          :placeholder="lang('t_note')"
          rows="3"
        />
      </b-form-group>
    </b-modal>

    <!-- ADD PHONE -->
    <b-modal
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      no-close-on-esc
      no-close-on-backdrop
      centered
      @ok="addPhone"
      :title="lang('t_addPhone')"
      v-model="modal_add_phone"
      max-width="540"
    >
      <b-form-group :label="lang('t_number')" label-for="phone">
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="new_phone"
            id="phone"
            placeholder="5XXXXXXXXX"
          />

          <b-button
            class="ml-50 px-50 py-50"
            @click="
              () => {
                is_approved = !is_approved;
                setApproved(data.item);
              }
            "
            size="sm"
            variant="flat-secondary"
          >
            <i
              :class="[
                is_approved
                  ? 'text-warning bi bi-star-fill'
                  : 'text-warning bi bi-star',
              ]"
            ></i>
          </b-button>

          <!-- <b-form-checkbox
            v-model="is_approved"
            id="is_approved"
            class="custom-control-primary"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox> -->
        </div>
      </b-form-group>
      <b-form-group :label="lang('t_relationTo')" label-for="owner">
        <b-form-input
          v-model="new_owner"
          id="owner"
          :placeholder="lang('t_relationTo')"
        />
      </b-form-group>
    </b-modal>

    <!-- ADD NOTE -->
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      centered
      @ok="addNote"
      :title="lang('t_addNote')"
      v-model="modal_add_note"
      max-width="540"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
    >
      <b-form-group label-for="note">
        <b-form-textarea
          v-model="new_note"
          id="textarea-default"
          :placeholder="lang('t_note')"
          rows="3"
        />
      </b-form-group>
    </b-modal>

    <!-- ADD EXTRA DATA -->
    <b-modal
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      no-close-on-esc
      no-close-on-backdrop
      @ok="addExtraData"
      centered
      :title="lang('t_addData')"
      v-model="modal_extra_data"
    >
      <b-form-group :label="lang('t_column')" label-for="column">
        <b-form-input
          v-model="customer_extra_data.column"
          id="column"
          :placeholder="lang('t_column')"
        />
      </b-form-group>
      <b-form-group :label="lang('t_value')" label-for="value">
        <b-form-input
          v-model="customer_extra_data.value"
          id="value"
          :placeholder="lang('t_value')"
        />
      </b-form-group>
    </b-modal>

    <!-- ADD FILE -->
    <b-modal
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      no-close-on-esc
      no-close-on-backdrop
      @ok="addFile"
      centered
      :title="lang('t_addFile')"
      v-model="modal_add_file"
    >
      <b-form-group :label="lang('t_column')" label-for="column">
        <b-form-file
          multiple
          class="w-75 pl-50"
          v-model="files"
          :placeholder="lang('t_uploadFileOnClick')"
          :drop-placeholder="lang('t_uploadFile')"
          :browse-text="lang('t_uploadFile')"
        />
      </b-form-group>
    </b-modal>

    <!-- CUSTOMER UPDATE -->
    <b-modal
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      @ok="updateCustomerInfo"
      centered
      :title="lang('t_customerUpdate')"
      v-model="modal_customer_update"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
    >
      <b-row>
        <b-col
          cols="6"
          v-for="(column, i) in Object.keys(tmp_selected_customer).filter(
            (e) =>
              ![
                'Müşteri No',
                'Araç',
                'Banka',
                'Tapu',
                'SSK',
                'Mernis',
                'Muzekkere',
                'Gsm Sorgu',
                'TCKN_masked',
                '_id',
                'attempts',
                'total_attempts',
                'status',
                'Kuyruk',
                'view_moment',
                'create_date',
                'create_time',
                'action_date',
                'action_time',
                'insert_date',
                'assign_date',
              ].includes(e)
          )"
          :key="i"
        >
          <b-form-group
            :label="
              column_labels.hasOwnProperty(column)
                ? column_labels[column]
                : column
            "
            :label-for="column"
          >
            <b-form-input trim v-model="tmp_selected_customer[column]" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <!-- CUSTOME PHONES UPDATE -->
    <b-modal
      size="lg"
      @ok="updateCustomerInfo"
      centered
      :title="lang('t_phonesinfoUpdate')"
      v-model="modal_custome_phones_update"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
    >
      <b-row v-for="(column, i) in tmp_phone_list" :key="i">
        <b-col cols="6">
          <b-form-group :label="'Telefon'" label-for="phone">
            <b-form-input trim v-model="column['Telefon']" />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group :label="'Sahibi'" label-for="owner">
            <b-form-input trim v-model="column['Sahibi']" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <!-- ADD PRODUCT ACTION -->
    <b-modal
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      no-close-on-esc
      no-close-on-backdrop
      centered
      @cancel="
        () => {
          p_action.action_date = null;
          p_action.note = '';
        }
      "
      @ok="addProductAction"
      :title="lang('t_addProductAction')"
      v-model="modal_product_action"
      max-width="540"
    >
      <b-form-group :label="lang('t_action')" label-for="finish_code">
        <v-select
          v-model="selected_product_action"
          @input="productActionChange"
          :options="this.finish_codes.filter((e) => e.is_product_finish_code)"
          label="finish_code"
          :placeholder="lang('t_action')"
          :reduce="(val) => val"
        ></v-select>
      </b-form-group>
      <b-form-group
        v-if="selected_product_action.require_date"
        :label="lang('t_actionDate')"
        label-for="action_date"
      >
        <!-- <b-form-datepicker id="action_date" v-model="p_action.action_date" /> -->
        <flat-pickr
          v-model="p_action.action_date"
          class="form-control"
          :title="lang('t_actionDate')"
          :placeholder="lang('t_actionDate')"
          :config="{
            dateFormat: 'Y-m-d H:i',
            enableTime: true,
            allowInput: true,
            time_24hr: true,
          }"
        />
      </b-form-group>
      <b-form-group :label="lang('t_note')" label-for="note">
        <b-form-textarea rows="5" v-model="p_action.note"></b-form-textarea>
      </b-form-group>
      <!-- <b-form-group
        :label="lang('t_installmentCount')"
        label-for="installment_count"
      >
        <b-form-input
          v-model="p_action.installment_count"
          id="installment_count"
          :placeholder="lang('t_installmentCount')"
          type="number"
        />
      </b-form-group>
      <b-form-group :label="lang('t_installmentAmount')" label-for="payment">
        <b-input-group prepend="₺">
          <b-form-input
            type="number"
            v-model="p_action.installment_payment"
            placeholder="0"
          />
        </b-input-group>
      </b-form-group> -->
    </b-modal>

    <!-- SPECIAL DATA -->
    <b-modal
      v-model="modal_special_data"
      static
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="modal_special_data_title"
      dialog-class="dClass75"
    >
      <div style="display: grid">
        <b-table
          thead-class="text-nowrap"
          thead-tr-class="text-nowrap"
          :items="extra_data[modal_special_data_title]"
        >
        </b-table>
      </div>
    </b-modal>

    <!-- OLD NOTES -->
    <b-modal
      size="lg"
      v-model="modal_old_notes"
      static
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="lang('t_oldNotes')"
    >
      <b-table responsive="" :items="old_notes"> </b-table>
    </b-modal>

    <!-- PRODUCT DETAILS -->
    <b-modal
      v-if="selected_product_details"
      v-model="modal_product_details"
      static
      cancel-variant="outline-secondary"
      :ok-title="lang('t_ok')"
      centered
      ok-only
      :title="selected_product_details['Ürün No']"
    >
      <div
        v-for="(key, i) in [
          'ASIL_ALACAK_TOPLAMI',
          'TAHSIL_ASIL_ALACAK_TOPLAMI',
          'FAIZ_TOPLAMI',
          'TAHSIL_FAIZ_TOPLAMI',
          'MASRAF_TOPLAMI',
          'TAHSIL_MASRAF_TOPLAMI',
          'KTVU_TOPLAMI',
          'TAHSIL_KTVU_TOPLAMI',
          'TAHSILAT_TOPLAMI',
          'BAKIYE_TOPLAMI',
          'SON_TAHSIL_TARIHI',
        ]"
        class="mb-50 text-truncate"
        :key="i"
      >
        <hr v-if="i % 2 == 0" />
        <span class="font-weight-bold">
          {{ key.split("_").join(" ") + ":" }}
        </span>

        <span
          v-b-tooltip.hover.top="selected_product_details[key]"
          v-b-tooltip.hover.v-secondary
          class="ml-50 font-weight-bolder"
        >
          {{ selected_product_details[key] }}
        </span>
      </div>
    </b-modal>
  </div>
</template>


<script>
import {
  BFormFile,
  BCardFooter,
  BContainer,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  VBTooltip,
  BFormSelect,
  BInputGroupAppend,
  BBadge,
  BTable,
  BPagination,
  BInputGroup,
  BListGroupItem,
  BListGroup,
  BFormCheckbox,
  BTabs,
  BTab,
  BModal,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
  BFormRadio,
  BInputGroupPrepend,
  BDropdownForm,
  BDropdownText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { $themeColors } from "@themeConfig";
import Cleave from "vue-cleave-component";
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {

  props: {
    selected_customer: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      crm_permissions: [],
      other_permissions: [],
      queues: [],
      old_notes: [],
      extra_data: [],
      files: null,
      is_progress: false,
      modal_script: false,
      modal_send_sms: false,
      modal_finish_code: false,
      modal_add_phone: false,
      modal_add_note: false,
      modal_add_file: false,
      modal_extra_data: false,
      modal_customer_update: false,
      modal_product_action: false,
      modal_custome_phones_update: false,
      modal_special_data: false,
      modal_old_notes: false,
      modal_product_details: false,
      modal_special_data_title: '',
      wavesurfer: null,
      currentTime: 0,
      extra_data_headers: [
        { key: "Tarih", label: "Tarih", sortable: true },
        { key: "Detay", label: "Detay", sortable: true },
      ],
      column_labels: {
        active: globalThis._lang('t_state'),
        status: globalThis._lang('t_status'),
        assigned_agent: globalThis._lang('t_agent'),
        attempts: globalThis._lang('t_attempts'),
        total_attempts: globalThis._lang('t_totalAttempts'),
        campaign: globalThis._lang('t_campaign'),
        finish_code: globalThis._lang('t_finishCode'),
        insert_date: globalThis._lang('t_insertDate'),
        action_date: globalThis._lang('t_actionDate'),
        action_time: globalThis._lang('t_actionTime'),
        create_date: globalThis._lang('t_createDate'),
        create_time: globalThis._lang('t_createTime'),
        ex_agent: globalThis._lang('t_exAgent'),
        assign_date: globalThis._lang('t_assignDate'),
        new_assets: globalThis._lang('t_newAssets'),
        reached: globalThis._lang('t_reached'),
        reached_date: globalThis._lang('t_reachedDate'),
      },
      selected_item: [],
      product_list: [

      ],
      product_list_header: [
        {
          "key": "show_details",
          "label": ""
        },
        {
          "key": "select",
          "label": ""
        },
        {
          "label": "Kurum Adı",
          "align": "left",
          "sortable": true,
          "key": "Kurum Adı",
          "class": "headerFont"
        },
        {
          "label": "Ürün No",
          "align": "left",
          "sortable": true,
          "key": "Ürün No",
          "class": "headerFont"
        },
        {
          "label": "Karton No ",
          "align": "left",
          "sortable": true,
          "key": "Karton No ",
          "class": "headerFont"
        },
        {
          "label": "Ürün Adı ",
          "align": "left",
          "sortable": true,
          "key": "Ürün Adı ",
          "class": "headerFont"
        },
        {
          "label": "Gelişme Sonucu",
          "align": "left",
          "sortable": true,
          "key": "Gelişme Sonucu",
          "class": "headerFont"
        },
        {
          "label": "Sonraki Arama Tarihi",
          "align": "left",
          "sortable": true,
          "key": "Sonraki Arama Tarihi",
          "class": "headerFont"
        },
        {
          "label": "Agent",
          "align": "left",
          "sortable": true,
          "key": "Agent",
          "class": "headerFont"
        },
        {
          "label": "Borç Tutarı",
          "align": "left",
          "sortable": true,
          "key": "Borç Tutarı",
          "class": "headerFont"
        },
        {
          "label": "Ek Alan 1",
          "align": "left",
          "sortable": true,
          "key": "Ek Alan 1",
          "class": "headerFont"
        },
        {
          "label": "Ek Alan 2",
          "align": "left",
          "sortable": true,
          "key": "Ek Alan 2",
          "class": "headerFont"
        },
        {
          "label": "İADE TARİHİ ",
          "align": "left",
          "sortable": true,
          "key": "İADE TARİHİ ",
          "class": "headerFont"
        },
        {
          "label": "Yükleme Tarihi",
          "align": "left",
          "sortable": true,
          "key": "Yükleme Tarihi",
          "class": "headerFont"
        }
      ],
      phone_log_header: [
        {
          label: `${globalThis._lang("t_direction").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Direction",
          class: "headerFont",
        },
        // {
        //   label: `${globalThis
        //     ._lang("t_recordingFile")
        //     .replace(/ /g, "\u00a0")}`,
        //   sortable: true,
        //   key: "RecordingFile",
        // },
        {
          label: `${globalThis
            ._lang("t_progresingDate")
            .replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "StartTime",
        },
        {
          label: `${globalThis._lang("t_queue").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Queue",
        },
        {
          label: `${globalThis._lang("t_agent").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Agent",
        },
        {
          label: `${globalThis._lang("t_finishCode").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Verdict",
        },
        {
          label: `${globalThis._lang("t_actionDate2").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "ActionDate",
        },

        // {
        //   label: `${globalThis._lang("t_transfer").replace(/ /g, "\u00a0")}`,
        //   sortable: true,
        //   key: "TransferEvent",
        // },
        {
          label: `${globalThis._lang("t_note").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "Note",
        },


      ],
      product_log_header: [
        {
          label: `${globalThis._lang("t_date").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "insert_date",
        },
        {
          label: `${globalThis._lang("t_agent").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "username",
        },
        {
          label: `${globalThis._lang("t_action").replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "action",
        },
        {
          label: `${globalThis
            ._lang("t_progresingDate")
            .replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "action_date",
        },
        {
          label: `${globalThis
            ._lang("t_note")
            .replace(/ /g, "\u00a0")}`,
          sortable: true,
          key: "note",
        },
        // {
        //   label: `${globalThis._lang("t_installmentCount").replace(/ /g, '\u00a0')}`,
        //   sortable: true,
        //   key: "installment_count",
        // },
        // {
        //   label: `${globalThis._lang("t_installmentAmount").replace(/ /g, '\u00a0')}`,
        //   sortable: true,
        //   key: "installment_payment",
        // },
      ],
      p_action: {
        action: "",
        action_date: "",
        note: "",
        // installment_count: "",
        // installment_payment: "",
      },
      selected_product_action: {},
      finish_codes: [],
      phone_list: [],
      phone_list_header: [
        { key: "show_details", label: "" },
        { key: "actions", label: "", thStyle: { width: '5vh' } },
      ],
      note_list: [],
      sms_list: [],
      activity_list: [],
      customer_extra_info: [],
      customer_extra_data: {
        column: "",
        value: "",
      },
      displaySound: false,
      sms_type: 'sms',
      selected_sms_settings: {},
      new_sms: '',
      sms_phone: '',

      f_action: {
        contact_id: "",
        finish_code: "",
        contact_number: "",
        note: "",
        last_action: false,
        action_date: "",
        call_direction: "",
      },
      p_action: {
        action: "",
        action_date: "",
        note: "",
        // installment_count: "",
        // installment_payment: "",
      },
      new_phone: '',
      new_owner: '',
      is_approved: '',
      tmp_selected_customer: {},
      tmp_phone_list: [],
      new_note: '',
      activitySearchQuery: '',
      activityLogFilters: {
        notes: true,
        logs: true,
        sms: true,
      },
      selected_product_details: null,
      PROTOCOL: globalThis.env.PROTOCOL,
      API_URL: globalThis.env.API_URL,
      PROJECT: globalThis.user.selected_project,
    };
  },
  components: {
    BFormFile,
    BDropdownText,
    BDropdownForm,
    WaveSurfer,
    AppTimeline,
    AppTimelineItem,
    BCardFooter,
    BInputGroupPrepend,
    BSpinner,
    BFormRadio,
    BContainer,
    Cleave,
    BFormDatepicker,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardActions,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormSelect,
    BInputGroupAppend,
    BBadge,
    BTable,
    BPagination,
    BInputGroup,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormInput,
    BTabs,
    BTab,
    BModal,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    flatPickr,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    FilteredActivityList() {
      return this.activity_list.filter(e =>
        ((this.activityLogFilters.notes || e.type !== 'note') &&
          (this.activityLogFilters.logs || e.type !== 'log') &&
          (this.activityLogFilters.sms || e.type !== 'sms')) &&
        ((e.insert_date && e.insert_date.includes(this.activitySearchQuery)) ||
          (e.agent && e.agent.includes(this.activitySearchQuery)) ||
          (e.note && e.note.includes(this.activitySearchQuery)))
      );
    }
  },
  methods: {
    async doCopy(text) {
      globalThis.LayoutContentRendererDefault.doCopy(text);

    },
    openProductDetails(product) {
      this.selected_product_details = product;
      this.modal_product_details = true;
    },
    test(key, customer) {
      console.log("test");
      console.log(key);
      console.log(customer[key]);
      console.log(typeof customer[key]);
      return customer[key];
    },

    sendData() {
      if (this.phone_list.length > 0) {
        const iframeWindow = this.$refs.customScript.contentWindow;
        const message = {
          customer: this.selected_customer,
          phone: globalThis.LayoutContentRendererDefault.current_call_info && globalThis.LayoutContentRendererDefault.current_call_info.Extra ? globalThis.LayoutContentRendererDefault.current_call_info.Extra.RemoteNumber : this.phone_list[0].Telefon,
          interaction_id: globalThis.LayoutContentRendererDefault.current_call_info ? globalThis.LayoutContentRendererDefault.current_call_info.InteractionID : '',
          endpoint: `${globalThis.env.PROTOCOL}://${globalThis.env.API_URL}`
        };
        const targetOrigin = "*";
        iframeWindow.postMessage(message, targetOrigin);
      }

    },

    async addFile() {
      if (!this.files || this.files.length === 0) {
        // alert("Lütfen bir dosya seçin.");
        return;
      }
      this.is_progress = true;

      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }
      formData.append("_id", this.selected_customer._id);

      try {
        await this.$http_in.post('crm/v1/CustomerFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        await this.getCustomerDetails();
        // alert("Dosya başarıyla yüklendi.");
      } catch (error) {
        console.error("Dosya yükleme hatası:", error);
        // alert("Dosya yüklenirken bir hata oluştu.");
      } finally {
        this.is_progress = false;
      }
    },
    openNote(note) {
      this.$swal({
        html: note,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        showClass: {
          popup: "animate__animated animate__flipInX",
        },
        buttonsStyling: false,
      });
    },
    maskPhoneNumber(phoneNumber) {
      var maskedNumber = phoneNumber.slice(0, -2).replace(/./g, '*') + phoneNumber.slice(-2);
      return maskedNumber;
    },
    openModalSpecialData: async function (type, ref) {
      this.modal_special_data_title = type;
      this.modal_special_data = true;
      this.$refs[ref].classList.remove('attention-button');
    },
    selection(item) {
      if (!this.selected_item.includes(item)) {
        this.selected_item.push(item);
      } else {
        this.selected_item = this.selected_item.filter((x) => x != item);
      }
    },
    addProductAction: async function () {
      console.log("this.selected_item", this.selected_item);

      for (const item of this.selected_item) {
        var response = (
          await this.$http_in.post(`crm/v1/ProductActions`, {
            customer_info: JSON.stringify(this.selected_customer),
            data: JSON.stringify({
              "customer_id": this.selected_customer["Müşteri No"],
              "queue": this.selected_customer["Kuyruk"] ?? 'NA',
              "product_id": item["Ürün No"],
              "username": globalThis.user.username,
              ...this.p_action,
              ...item
            }),
          })
        ).data;
      }
      this.p_action.action_date = null;
      this.p_action.note = "";
      this.modal_product_action = false;
      this.selected_item = [];
      await this.getCustomerDetails();
    },
    productActionChange(item) {
      this.p_action.action = item.finish_code;
    },
    setActivityLogFilters(type) {
      this.activityLogFilters[type] = !this.activityLogFilters[type];
      localStorage.setItem("ActivityLogFilters", JSON.stringify(this.activityLogFilters));
    },
    convertDateFormat(timestamp) {
      const dateTime = new Date(timestamp);

      if (isNaN(dateTime.getTime())) {
        return 'Invalid date';
      }

      const day = ('0' + dateTime.getDate()).slice(-2);
      const month = ('0' + (dateTime.getMonth() + 1)).slice(-2);
      const year = dateTime.getFullYear();
      const hours = ('0' + dateTime.getHours()).slice(-2);
      const minutes = ('0' + dateTime.getMinutes()).slice(-2);
      const seconds = ('0' + dateTime.getSeconds()).slice(-2);

      const newFormat = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      return newFormat;
    },
    addNote: async function () {

      this.modal_add_note = false;
      var response = (
        await this.$http_in.post(`crm/v1/CustomerNote`, {
          customer_info: JSON.stringify(this.selected_customer),
          data: JSON.stringify({
            agent: globalThis.username,
            note: this.new_note,
          }),
        })
      ).data;
      this.new_note = "";
      this.getCustomerDetails();
    },
    updateCustomerInfo: async function () {
      try {
        this.is_progress = true;
        let _personal_info = { _id: this.selected_customer._id };
        for (const item of Object.keys(this.tmp_selected_customer)) {
          if (
            this.tmp_selected_customer[item] != this.selected_customer[item]
          ) {
            _personal_info[item] = this.tmp_selected_customer[item];
          }
        }

        let _phone_info = [];
        for (let index = 0; index < this.tmp_phone_list.length; index++) {
          if (
            this.phone_list[index]["Telefon"] !=
            this.tmp_phone_list[index]["Telefon"] ||
            this.phone_list[index]["Sahibi"] !=
            this.tmp_phone_list[index]["Sahibi"]
          ) {
            _phone_info.push({
              _phone: this.phone_list[index]["Telefon"],
              ...this.tmp_phone_list[index],
            });
          }
        }

        var response = (
          await this.$http_in.post(`crm/v1/SetCustomer`, {
            customer_info: _personal_info,
            phone_info: _phone_info,
          })
        ).data;

        if (response.acknowledged) {
          this.selected_customer = {
            ...this.selected_customer,
            ..._personal_info,
          };

          await this.getCustomerDetails();

          this.$swal({
            icon: "success",
            title: globalThis._lang("t_processSuccess"),
            confirmButtonText: this.lang("t_ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      } catch (error) {
      } finally {
        this.is_progress = false;
      }
    },
    openUpdateCustomerModal() {
      this.modal_customer_update = true;
      this.tmp_selected_customer = JSON.parse(
        JSON.stringify(this.selected_customer)
      );
      this.tmp_phone_list = JSON.parse(JSON.stringify(this.phone_list));
    },
    openUpdateCustomerPhonesModal() {
      this.modal_custome_phones_update = true;
      this.tmp_selected_customer = JSON.parse(
        JSON.stringify(this.selected_customer)
      );
      this.tmp_phone_list = JSON.parse(JSON.stringify(this.phone_list));
    },
    addExtraData: async function () {
      this.customer_extra_info.push(this.customer_extra_data);

      var response = (
        await this.$http_in.post(`crm/v1/CustomerExtraData`, {
          customer_info: this.selected_customer,
          data: this.customer_extra_info,
        })
      ).data;

      this.modal_extra_data = false;
      this.customer_extra_data = {
        column: "",
        value: "",
      };
    },
    deleteExtraData: async function (item) {

      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        console.log("res", result);
        if (result.value) {

          let index = this.customer_extra_info.findIndex((x) => x === item);
          this.customer_extra_info.splice(index, 1);

          var response = (
            await this.$http_in.post(`crm/v1/CustomerExtraData`, {
              customer_info: this.selected_customer,
              data: this.customer_extra_info,
            })
          ).data;
        }
      });


    },
    addPhone: async function () {
      try {
        var obj = Object.create(null);
        obj["Telefon"] = this.new_phone.split(" ").join("");
        obj["Sahibi"] = this.new_owner;
        obj["Onaylı"] = this.is_approved;
        obj["phone_log"] = [];
        obj["is_research"] = true;
        obj["attempts"] = 0;
        obj["total_attempts"] = 0;
        this.phone_list.push(obj);
        this.modal_add_phone = false;
      } catch (e) {
        console.log("error", e);
      }

      var response = (
        await this.$http_in.post(`crm/v1/CustomerPhone`, {
          customer_info: JSON.stringify(this.selected_customer),
          data: JSON.stringify(obj),
        })
      ).data;
    },

    saveFinishCode: async function () {
      if (!this.f_action.finish_code) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      if (this.f_action.finish_code.require_date && !this.f_action.action_date) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      var response = (
        await this.$http_in.post(`crm/v1/ManualCallAction`, {
          customer_info: this.selected_customer,
          data: this.f_action,
        })
      ).data;

      this.f_action = {};
      this.getCustomerDetails();
    },

    openDeleteModal(item) {

      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        console.log("res", result);
        if (result.value) {
          this.deletePhone(item);
        }
      });
    },
    deletePhone: async function (selected_phone) {
      this.phone_list.splice(this.phone_list.indexOf(selected_phone), 1);
      var response = (
        await this.$http_in.delete(
          `crm/v1/CustomerPhone/${this.selected_customer._id}/${selected_phone.Telefon}`
        )
      ).data;
    },
    setApproved: async function (item) {
      var response = (
        await this.$http_in.post(`crm/v1/SetPhoneApproved`, {
          customer_info: JSON.stringify(this.selected_customer),
          data: item,
        })
      ).data;

      if (response.ok == 1) {
      }
    },

    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      let remainingSeconds = seconds % 60;

      minutes = minutes < 10 ? '0' + minutes : minutes;
      remainingSeconds = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds;

      return minutes + ":" + remainingSeconds;
    },
    setSoundSpeed(speed) {
      if (this.wavesurfer) {
        this.wavesurfer.setPlaybackRate(speed, true);
        this.wavesurfer.play();
      }
    },
    loadSound(item) {
      if (this.crm_permissions.includes('crm_display_sound_just_own') && item.Agent != globalThis.user.username) {
        return;
      }
      if (item.RecordingFile && this.crm_permissions.includes("crm_display_sound")) {
        let url = `${globalThis.env["PROTOCOL"]}://${globalThis.env.RECORDING_URL}/recordings/${item.RecordingFile}`;
        console.log(item);
        var myDiv = document.getElementById(`waveform_${this.selected_customer._id}`);
        myDiv.innerHTML = null;
        this.wavesurfer = null;
        this.currentTime = 0;
        this.wavesurfer = globalThis.WaveSurfer.create({
          height: 60,

          container: `#waveform_${this.selected_customer._id}`,
          waveColor: '#F0F0F1',
          progressColor: $themeColors.primary,
          // url: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav',
          url: url,
          audioRate: 1,
          // splitChannels: [
          //   {
          //     waveColor: 'rgb(200, 0, 200)',
          //     progressColor: 'rgb(100, 0, 100)',
          //   },
          //   {
          //     waveColor: 'rgb(0, 200, 200)',
          //     progressColor: 'rgb(0, 100, 100)',
          //   },
          // ],
          // Set a bar width

          barWidth: 4,
          // Optionally, specify the spacing between bars
          barGap: 3,
          // And the bar radius
          barRadius: 8,
        })
        console.log("wavesurfer", this.wavesurfer)

        this.wavesurfer.once('interaction', () => {
          console.log("interaction")
          this.wavesurfer.play()
        })


        this.wavesurfer.on('audioprocess', (currentTime) => this.currentTime = currentTime);

        this.displaySound = true;
      }


    },
    closeSound() {
      this.displaySound = false;
      var myDiv = document.getElementById(`waveform_${this.selected_customer._id}`);
      myDiv.innerHTML = null;
      this.wavesurfer = null;
      this.currentTime = 0;
    },

    start_preview_call(phone) {
      if (this.crm_permissions.includes('script') && this.script && this.script.length > 0) {
        this.modal_script = true
      }
      globalThis.LayoutContentRendererDefault.preview_call_request(phone, this.selected_customer._id);

    },
    getVisibleStatus(item) {
      if (this.crm_permissions.includes("crm_display_sound")) {
        return true;
      }

      if (this.other_permissions.includes("agent_display_sound") && item.Agent == globalThis.user.username) {
        return true;
      }
      return false;
    },
    getCustomerDetails: async function () {

      var response = (
        await this.$http_in.post(`crm/v1/CustomerDetails`, {
          data: JSON.stringify(this.selected_customer),
        })
      ).data;
      console.log("details", response);
      this.selected_customer["action_date"] = response.action_date;
      this.selected_customer["action_time"] = response.action_time;
      this.selected_customer["finish_code"] = response.finish_code;
      this.selected_customer["status"] = response.status;
      this.selected_customer["files"] = response?.files ?? [];

      this.customer_extra_info = [];
      this.phone_list = [];
      this.product_list = [];
      this.note_list = [];
      this.sms_list = [];

      this.customer_extra_info = [...response.extra_data] ?? [];

      this.phone_list = [...response?.phones] ?? [];
      this.sms_list = [...response?.sms] ?? [];
      // this.phone_list = [...response?.phones.filter(e => e.status != 'P')] ?? [];
      this.product_list = [...response?.products] ?? [];

      for (const item of this.product_list) {
        item.is_selected = false;
      }
      this.note_list = [...response?.notes] ?? [];
      this.note_list = this.note_list.map(note => {
        if ([null, undefined].includes(note.insert_date)) {
          note.insert_date = '01.01.1970 00:00:00'
        }
        if (note.is_log) {
          return {
            ...note,
            insert_date: note.insert_date,
            icon: 'InfoIcon',
            color: 'info',
            "type": 'log',
            "_date": note.insert_date.split(' ')[0].split('.').reverse().join('-') + ' ' + note.insert_date.split(' ')[1],

          };
        } else {
          return {
            ...note,
            insert_date: note.insert_date,
            icon: 'FileTextIcon',
            color: 'success',
            "type": 'note',
            "_date": note.insert_date.split(' ')[0].split('.').reverse().join('-') + ' ' + note.insert_date.split(' ')[1],

          }
        }
      });

      this.activity_list = [...this.note_list];
      for (const row of this.sms_list) {
        this.activity_list.push({
          "insert_date": this.convertDateFormat(row.Tarih),
          "agent": row.Gönderen,
          "note": (this.other_permissions.includes("mask_remote_number") ? this.maskPhoneNumber(row.Telefon) : row.Telefon) + " / " + row.Mesaj,
          "icon": 'MessageCircleIcon',
          "color": 'primary',
          "type": 'sms',
          "_date": row.Tarih,
        });
      }
      this.activity_list = this.activity_list.sort((a, b) => {

        const dateA = new Date(a._date);
        const dateB = new Date(b._date);
        return dateB - dateA;
      });
      this.phone_list = this.phone_list.sort((a, b) => {
        return (a.Onaylı === b.Onaylı) ? 0 : a.Onaylı ? -1 : 1;
      });

      console.log("activity_list", this.activity_list)
      this.extra_data = {
        Araç: [...(response["Araç"] ?? [])],
        Banka: [...(response["Banka"] ?? [])],
        SSK: [...(response["SSK"] ?? [])],
        Tapu: [...(response["Tapu"] ?? [])],
        Mernis: [...(response["Mernis"] ?? [])],
        Muzekkere: [...(response["Muzekkere"] ?? [])],
        "Gsm Sorgu": [...(response["Gsm Sorgu"] ?? [])],
      };

      let b_refs = {
        Araç: 'vehicleButton',
        Banka: 'bankButton',
        SSK: 'sskButton',
        Tapu: 'landRegistryButton',
        Mernis: 'mernisButton',
        "Gsm Sorgu": 'gsmSorguButton',
        Muzekkere: 'muzekkereButton',
      };

      for (const btn of Object.keys(b_refs)) {
        if (response[btn]) {
          if (response[btn].find(e => new Date(e["SON BULMA TARİHİ"]) > new Date(this.selected_customer.insert_date == '' ? null : this.selected_customer.insert_date))) {
            this.$refs[b_refs[btn]].classList.add('attention-button');
          }
        }
      }


      // this.phone_list.map((e) => {
      //   e.phone_log = response.phone_logs.filter(
      //     (f) => e["Telefon"].substr(-10) == f.RemoteNumber.substr(-10)
      //   );
      // });
      if (response.phone_logs) {
        response.phone_logs = response.phone_logs.filter(e => globalThis.user.selected_queues.includes(e.Queue) || ['null', null, undefined, 'undefined', 'NA'].includes(e.Queue));
        for (const row of response.phone_logs) {
          for (const key of Object.keys(row)) {
            if (!["Note", "Verdict", "RecordingFile"].includes(key)) {
              row[key] =
                typeof row[key] == "string"
                  ? row[key].split("-").join(".").replace(/ /g, "\u00a0")
                  : row[key];
            }
          }
        }
      }


      for (const item of this.phone_list) {
        if (this.crm_permissions.includes("show_phone_details")) {
          item._showDetails = true;
        }
        if (response.phone_logs) {
          item.phone_log = response.phone_logs.filter(
            (f) => item["Telefon"].substr(-10) == f.RemoteNumber.substr(-10)
          );
        } else {
          item.phone_log = [];
        }

      }
      this.phone_list = this.phone_list.map((item) => ({ ...item }));

      this.product_list_header = [
        { key: "show_details", label: "" },
        { key: "select", label: "" },
      ];
      this.phone_list_header = [
        { key: "show_details", label: "" },
      ];
      let tmp_set = new Set();
      if (response.phones.length > 0) {
        for (const row of response.phones) {
          for (const item of Object.keys(row)) {
            if (
              ![
                "phone_log",
                "phone_logs",
                "cycles",
                "attempts",
                "total_attempts",
                "status",
                "Onaylı",
                "lastCallTime",
                "is_research",
                "_showDetails",
              ].includes(item)
            ) {
              tmp_set.add(item);
            }
          }
        }
        for (const item of Array.from(tmp_set)) {
          this.phone_list_header.push({
            label:
              this.column_labels[item] != undefined
                ? this.column_labels[item].replace(/ /g, "\u00a0")
                : item.replace(/ /g, "\u00a0"),
            align: "left",
            sortable: true,
            key: item,
            class: "headerFont",
          });
        }

        this.phone_list_header.push({ key: "actions", label: "", thStyle: { width: '5vh' } });
      }

      if (response.products.length > 0) {
        for (const item of Object.keys(response.products[0])) {
          if (!["is_selected", "product_logs", "ASIL_ALACAK_TOPLAMI",
            "TAHSIL_ASIL_ALACAK_TOPLAMI",
            "FAIZ_TOPLAMI",
            "TAHSIL_FAIZ_TOPLAMI",
            "MASRAF_TOPLAMI",
            "TAHSIL_MASRAF_TOPLAMI",
            "KTVU_TOPLAMI",
            "TAHSIL_KTVU_TOPLAMI",
            "TAHSILAT_TOPLAMI",
            "SON_TAHSIL_TARIHI"].includes(item)) {
            this.product_list_header.push({
              label: item == 'Agent' ? globalThis._lang('t_agent') : item.replace(/ /g, "\u00a0"),
              align: "left",
              sortable: true,
              key: item == 'Agent' ? item.split('@')[0] : item,
              class: "headerFont ",
              tdClass: 'text-nowrap'
            });
          }
        }
      }

      this.is_progress = false;

    },
    getLabelName(key) {
      return this.column_labels.hasOwnProperty(key) ? this.column_labels[key] : key;
    },
    rowClassPhone(item, type) {
      if (item && item.status === "P") return "table-danger table-style";
      return ""
    },
    getOldNotes: async function () {
      var response = (
        await this.$http_in.post(`crm/v1/OldNotes`, {
          data: JSON.stringify(this.selected_customer),
        })
      ).data;
      this.old_notes = [];
      for (let row of response) {
        for (let field of Object.keys(row)) {
          row[field] = row[field] ? row[field].replace(/ /g, "\u00a0").replaceAll('-', '/') : row[field];
        }
      }
      this.old_notes = [...response];
      // console.log('details', response);
    },

  },
  mounted: async function () {
    this.crm_permissions = globalThis.permissions["crm"] ?? [];
    this.other_permissions = globalThis.permissions["other"] ?? [];
    this.queues = globalThis.queues;

    this.finish_codes = [];
    let fc = globalThis.permissions["finish_code"]
    for (const row of globalThis.finish_codes) {
      if (fc.includes(row['finish_code'])) {
        this.finish_codes.push(row);
      }
    }


    this.is_progress = true;
    this.activityLogFilters = JSON.parse(localStorage.getItem("ActivityLogFilters") ?? JSON.stringify({
      notes: true,
      logs: true,
      sms: true,
    }));
    await this.getCustomerDetails();
    // if (this.crm_permissions.includes('script') && this.script && this.script.length > 0) {
    //   this.modal_script = true
    // }
    this.getOldNotes();

    this.is_progress = false;
  },
  beforeDestroy() {
    // localStorage.setItem("crmData", JSON.stringify(this.$data));
  },
};

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.table-style {
  color: black !important;
}
// .table-style :hover {
//   color: white !important;
// }

.cell-style {
  color: black;
}

.override-overflow {
  overflow: hidden !important;
}
</style>

<style>
#waveform {
  cursor: pointer;
  /* position: relative; */
}
#hover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  width: 0;
  mix-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
}
#waveform:hover #hover {
  opacity: 1;
}
#time,
#duration {
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -1px;
  transform: translateY(-50%);
  font-size: 11px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px;
  color: #ddd;
}
#time {
  left: 0;
}
#duration {
  right: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}
</style>

<style scoped>
/* @import "@core/scss/vue/libs/vue-flatpicker.scss"; */
#waveform {
  cursor: pointer;
  height: 100%;
  /* position: relative; */
}
#hover {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  pointer-events: none;
  height: 100%;
  width: 0;
  mix-blend-mode: overlay;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.2s ease;
}
#waveform:hover #hover {
  opacity: 1;
}
#time,
#duration {
  position: absolute;
  z-index: 11;
  top: 50%;
  margin-top: -1px;
  transform: translateY(-50%);
  font-size: 11px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px;
  color: #ddd;
}
#time {
  left: 0;
}
#duration {
  right: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}

.modalBodyClass {
  height: 40rem;
  overflow-y: auto;
}
.divClass {
  word-wrap: break-word;
  max-width: 100%;
  overflow-wrap: break-word;
}
.attention-button {
  color: white;
  border: none;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  display: inline-block; /* Inline-block or block level necessary for transform */
  animation: bounce-shake 2s ease-in-out infinite;
}

@keyframes bounce-shake {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-10px) rotate(-5deg);
  }
  50% {
    transform: translateY(0) rotate(0deg);
  }
  75% {
    transform: translateY(-5px) rotate(5deg);
  }
}
</style>



